import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type { initializeApiDataRouteHandler as TinitializeApiDataRouteHandler } from 'o365.pwa.declaration.sw.routehandlers.api.data.handler.d.ts';
import type { initializeApiDataExportRouteHandler as TinitializeApiDataExportRouteHandler } from 'o365.pwa.declaration.sw.routehandlers.api.data.export.handler.d.ts';
import type { initializeApiFileRouteHandler as TinitializeApiFileRouteHandler } from 'o365.pwa.declaration.sw.routehandlers.api.file.d.ts';
import type { initializeApiPwaRouteHandler as TinitializeApiPwaRouteHandler } from 'o365.pwa.declaration.sw.routehandlers.api.pwa.d.ts';
import type { initializeWebResourceRouteHandler as TinitializeWebResourceRouteHandler } from 'o365.pwa.declaration.sw.routehandlers.webResources.d.ts';
import type { initializeDefaultRouteHandler as TinitializeDefaultRouteHandler } from 'o365.pwa.declaration.sw.routehandlers.initializeDefaultRouteHandler.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { initializeApiDataRouteHandler } = self.o365.importScripts<{ initializeApiDataRouteHandler: typeof TinitializeApiDataRouteHandler }>('o365.pwa.modules.sw.routehandlers.api.data.handler.ts');
    const { initializeApiDataExportRouteHandler } = self.o365.importScripts<{ initializeApiDataExportRouteHandler: typeof TinitializeApiDataExportRouteHandler }>('o365.pwa.modules.sw.routehandlers.api.data.export.handler.ts');
    const { initializeApiFileRouteHandler } = self.o365.importScripts<{ initializeApiFileRouteHandler: typeof TinitializeApiFileRouteHandler }>('o365.pwa.modules.sw.routehandlers.api.file.ts');
    const { initializeApiPwaRouteHandler } = self.o365.importScripts<{ initializeApiPwaRouteHandler: typeof TinitializeApiPwaRouteHandler }>('o365.pwa.modules.sw.routehandlers.api.pwa.ts');
    const { initializeWebResourceRouteHandler } = self.o365.importScripts<{ initializeWebResourceRouteHandler: typeof TinitializeWebResourceRouteHandler }>('o365.pwa.modules.sw.routehandlers.webResources.ts');
    const { initializeDefaultRouteHandler } = self.o365.importScripts<{ initializeDefaultRouteHandler: typeof TinitializeDefaultRouteHandler }>('o365.pwa.modules.sw.routehandlers.initializeDefaultRouteHandler.ts');
    
    const RouteHandlers = {
        initializeDefaultRouteHandlers(customRouteHandlers?: Array<Function>) {
            customRouteHandlers?.forEach((customRoutHandler) => {
                customRoutHandler();
            });

            initializeApiDataRouteHandler();
            initializeApiDataExportRouteHandler();
            initializeApiFileRouteHandler();
            initializeApiPwaRouteHandler();
            initializeWebResourceRouteHandler();
            initializeDefaultRouteHandler();
        },

        initializeApiDataRouteHandler,
        initializeApiDataExportRouteHandler,
        initializeApiFileRouteHandler,
        initializeApiPwaRouteHandler,
        initializeWebResourceRouteHandler,
        initializeDefaultRouteHandler
    };

    self.o365.exportScripts(RouteHandlers);
})();
